import React, { useState, useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBowlFood,
  faCheck,
  faChild,
  faCrosshairs,
  faLocation,
  faMapMarker,
  faMapMarkerAlt,
  faShower,
  faStar,
  faWheelchair
} from '@fortawesome/free-solid-svg-icons'
import noImage from '../../Images/Hotels/no-img.jpg'
import moment from 'moment'
import Loader from '../../Components/Loading/Loader'
import bgimage from '../../Images/Hotels/bg.jpg'
import image from '../../Images/View Detail/1.jpeg'
import { useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  ApiEndPoint,
  Hotelapitoken
} from '../../Components/GlobalData/GlobalData'
import { fetchCheckoutDetail } from '../../Redux/Actions/actions'
import Images from '../../Components/Images/images'
import Layout from '../../Components/Layout/Layout'
import RoomFacilities from '../../Components/Hotel/RoomFacility'
import Axios from 'axios'
import HotelSearch from '../../Components/SearchBar/HotelSearch'
import ImageGallery from 'react-image-gallery'
import { Tooltip, Whisper } from 'rsuite'
function HotelDetail () {
  const location = useLocation()
  const dispatch = useDispatch()
  const todayDate = new Date()
  const apitoken = Hotelapitoken()
  var endpoint = ApiEndPoint()
  const { index } = location.state || {}
  const [selectedDate, setSelectedDate] = useState(null)
  const [activeButton, setActiveButton] = useState(null)
  const [selectedRooms, setSelectedRooms] = useState([])
  const [result, setResult] = useState([])
  const [loading, setLoading] = useState(false)
  const [showPrice, setShowPrice] = useState(true)
  const [baseCName, setBaseCName] = useState('GBP')
  const [counter, setCounter] = useState(0)

  const [hotelSelectedRooms, setHotelSelectedRooms] = useState([])

  const navigate = useNavigate()
  const hoteldetail = useSelector(state => state.hotels.hoteldetail)
  const seleectedhotelfromlist = useSelector(state => state.hotels.hotels[0])
  let { id } = useParams();
  const CurrencyRates = useSelector(state => state.hotels.Currency)
  const GBPCurrencyRates = useSelector(state => state.hotels.AllCurrency)
  const ReduxSearchData = useSelector(state => state.hotels.hsearch)

  let Checkin = moment(ReduxSearchData.check_in)
  let checkout = moment(ReduxSearchData.check_out)
  let daysBetween = Math.abs(checkout.diff(Checkin, 'days'))
  const latitude = hoteldetail.latitude
  const longitude = hoteldetail.longitude
  var mapUrl =
    'https://maps.google.com/maps?width=50%25&height=600&hl=en&q=' +
    latitude +
    ',' +
    longitude +
    '&t=&z=19&ie=UTF8&iwloc=B&output=embed'

  useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      const sectionIds = [
        'section-1',
        'section-2',
        'section-3',
        'section-4',
        'section-5',
        'section-6',
        'section-7'
      ]

      // Find the section that is currently in view
      for (const id of sectionIds) {
        const section = document.getElementById(id)
        if (section) {
          const rect = section.getBoundingClientRect()
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            setActiveButton(id)
            break
          }
        }
      }
    }

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll)

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  const handleButtonClick = buttonName => {
    setActiveButton(buttonName)
  }
  const handleButtonClick2 = buttonName => {
    setActiveButton(buttonName)
  }

  useEffect(() => {
    let NewArray = []
    let occurrences = {}
    // Iterate over the adults and childs arrays
    for (let i = 0; i < ReduxSearchData.Adults.length; i++) {
      let adult = ReduxSearchData.Adults[i]
      let child = ReduxSearchData.children[i]
      // Create a unique identifier for the pair
      let pairKey = `${adult}-${child}`

      // Update the occurrences object
      occurrences[pairKey] = (occurrences[pairKey] || 0) + 1

      // Only add to the result if the current qty is the maximum
      if (occurrences[pairKey] === 1) {
        NewArray.push({
          adult,
          child,
          qty: occurrences[pairKey],
          status: false
        })
      } else {
        // Update the qty for the existing entry
        NewArray.find(
          entry => entry.adult === adult && entry.child === child
        ).qty = occurrences[pairKey]
      }
    }

    setResult(NewArray)
  }, [ReduxSearchData])
  // const selectedRoomData = (index, event) => {
  //   event.preventDefault()
  //   event.stopPropagation()

  //   if (selectedRooms.some(room => room.index === index)) {
  //     const selectedroom = hoteldetail.rooms_options[index]
  //     setCounter(counter - selectedroom.rooms_qty)
  //     // Check if there's a room with the same index in selectedRooms
  //     const updatedSelectedRooms = selectedRooms.filter(
  //       room => room.index !== index
  //     )
  //     const newroom = hotelSelectedRooms.filter(item => item.index !== index)
  //     // Update the selectedRooms state with the new array
  //     setSelectedRooms(updatedSelectedRooms)
  //     setHotelSelectedRooms(newroom)
  //   } else {
  //     if (counter < ReduxSearchData.room) {

  //       const selectedroom = hoteldetail.rooms_options[index]
  //       const key = { rateKey: selectedroom.booking_req_id, index: index }
  //       const newSelectedRoom = {
  //         room_rate_key: JSON.stringify(key),
  //         rooms_qty: selectedroom.rooms_qty,
  //         index: index,
  //         roomdetail:selectedroom.adults+selectedroom.childs
  //       }
  //       setCounter(counter + selectedroom.rooms_qty)
  //       setSelectedRooms([...selectedRooms, newSelectedRoom])
  //       setHotelSelectedRooms([...hotelSelectedRooms, key])
  //     } else {
  //       toast.error('Select the room according to the search criteria.', {
  //         position: toast.POSITION.TOP_RIGHT
  //     });
  //     }
  //   }
  // };
  const selectedRoomData = (index, event) => {
    event.preventDefault()
    event.stopPropagation()
    const selectedroom = hoteldetail.rooms_options[index]
    for (let i = 0; i < result.length; i++) {
      if (
        result[i].adult === selectedroom.adults &&
        result[i].child === selectedroom.childs &&
        result[i].qty === selectedroom.rooms_qty
      ) {
        if (result[i].status === false) {
          const key = { rateKey: selectedroom.booking_req_id, index: index }
          const newSelectedRoom = {
            room_rate_key: JSON.stringify(key),
            rooms_qty: selectedroom.rooms_qty,
            index: index
          }
          result[i].status = true
          setSelectedRooms([...selectedRooms, newSelectedRoom])
          setHotelSelectedRooms([...hotelSelectedRooms, key])
        } else {
          if (selectedRooms.some(room => room.index === index)) {
            const updatedSelectedRooms = selectedRooms.filter(
              room => room.index !== index
            )
            const newroom = hotelSelectedRooms.filter(
              item => item.index !== index
            )
            setSelectedRooms(updatedSelectedRooms)
            setHotelSelectedRooms(newroom)
            result[i].status = false
          } else {
            toast.error('Select the room according to the search criteria.', {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        }
      }
    }
  }
  const BookRoom = async () => {
    if (hotelSelectedRooms.length !== 0) {
      if (hotelSelectedRooms.length !== result.length) {
        toast.error(
          'Please Select the room according to the search criteria.',
          {
            position: toast.POSITION.TOP_RIGHT
          }
        )
        return
      }

      const roomdata = {
        rooms_select_data: JSON.stringify(selectedRooms),
        hotel_index: index,
        hotelbeds_select_room: hotelSelectedRooms
      }
      const finaldata = {
        token: apitoken,
        request_data: JSON.stringify(roomdata),
        selected_hotel: JSON.stringify(seleectedhotelfromlist),
        selected_hotel_details: JSON.stringify({
          check_in: ReduxSearchData.check_in,
          check_out: ReduxSearchData.check_out,
          hotel_address: hoteldetail.hotel_address,
          hotel_country: hoteldetail.hotel_country,
          hotel_city: hoteldetail.hotel_city,
          latitude: hoteldetail.longitude,
          longitude: hoteldetail.latitude
        }),
        req_index: index
      }
      setLoading(true)
      try {
        const response = await Axios.post(
          endpoint + '/api/hotels/checkavailability_Live',
          finaldata,
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
              // Required for CORS support to work
              'Access-Control-Allow-Credentials': true // Required for cookies, authorization headers with HTTPS
              // 'Access-Control-Allow-Headers':
              //   'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
              // "Access-Control-Allow-Methods": "POST, OPTIONS"
            }
          }
        )
       
        if (response.data.status === 'error') {
          setLoading(false)
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          })
          return
        }
        if (response.data !== '') {
          dispatch(fetchCheckoutDetail(response.data.hotels_data))
          navigate('/hotel_checkout')
        }
        setLoading(false)
      } catch (error) {
        // Handle errors here
        setLoading(false)
        console.error('Error:', error)
      }
    } else {
      toast.error('Please Select Room First.', {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }
  const renderPrice = (price, currency) => {
    var currencyData = JSON.parse(localStorage.getItem('AllHotelCurr'))
    var selectedcurr = localStorage.getItem('DefaultCurrency')
    if (selectedcurr === currency || currencyData === null) {
      setShowPrice(false)
      if (Number(price) % 1 !== 0) {
        return Number(price).toFixed(2)
      }
      return Number(price)
    } else {
      var filterroomcurrdata = currencyData.filter(
        item => item.base_code === currency
      )
      if (CurrencyRates === undefined) {
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName] // Use square brackets to access the property
        var baseprice = Number(gbpprice) * Number(price)
      } else {
        var select123 = CurrencyRates.selectedcurrency
        const gbpprice = filterroomcurrdata[0].conversion_rates[baseCName]
        var baseprice1 = Number(gbpprice) * Number(price)
        const gbpprice2 = GBPCurrencyRates.conversion_rates[select123] // Use square brackets to access the property
        var baseprice = Number(gbpprice2) * Number(baseprice1)
      }
      if (Number(baseprice) % 1 !== 0) {
        return Number(baseprice).toFixed(2)
      }
      return baseprice
    }
  }
  const calculateMarkup = price => {
    if (Object.keys(hoteldetail).length !== 0) {
      let markupprice = 0
      let adminmarkupprice = 0
      let clientmarkupprice = 0
      let finalpricemarkup = Number(price)
      if (Number(hoteldetail.admin_markup) !== 0) {
        if (hoteldetail.admin_markup_type === 'Percentage') {
          markupprice = (price * Number(hoteldetail.admin_markup)) / 100
        } else {
          markupprice = Number(hoteldetail.admin_markup)
        }
        adminmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      if (Number(hoteldetail.customer_markup) !== 0) {
        if (hoteldetail.customer_markup_type === 'Percentage') {
          markupprice =
            (finalpricemarkup * Number(hoteldetail.customer_markup)) / 100
        } else {
          markupprice = Number(hoteldetail.customer_markup)
        }
        clientmarkupprice = markupprice
        finalpricemarkup += markupprice
      }
      return finalpricemarkup.toFixed(2)
    }
  }
  return (
    <>
      <ToastContainer />
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <main>
            <section class='hero_in hotels_detail'>
              <div class='wrapper'>
                <div class='container'>
                  <h1 class='fadeInUp'>
                    <span></span>
                    {hoteldetail.hotel_name}
                  </h1>
                </div>
              </div>
            </section>
            <div class='bg_color_1'>
              <nav class='secondary_nav sticky_horizontal'>
                <div class='container'>
                  <ul class='clearfix'>
                    <li>
                      <a href='#description' class='active'>
                        Description
                      </a>
                    </li>
                    <li>
                      <a href='#reviews'>Reviews</a>
                    </li>
                  </ul>
                </div>
              </nav>
              <div class='container margin_60_35'>
                <div class='row'>
                  <div class='col-lg-8'>
                    <section id='description'>
                      <h2>Description</h2>
                      {hoteldetail.hotel_provider === 'tbo' ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: hoteldetail.description
                          }}
                        />
                      ) : (
                        <p>{hoteldetail.description} </p>
                      )}
                      <div>
                        <ul className='d-flex  justify-content-between align-items-center '>
                          <li>
                            <h6>Check In</h6>
                            <p>{moment(ReduxSearchData.check_in).format('ll')}</p>
                          </li>
                          <li>
                            <h6>Check out</h6>
                            <p>{moment(ReduxSearchData.check_out).format('ll')}</p>
                          </li>
                          <li>
                            <h6>No of Nights</h6>
                            <p>{daysBetween}</p>
                          </li>
                        </ul>
                      </div>
                      <div class='row'>
                        {hoteldetail.hotel_facilities.map((item, index) => (
                          <div key={index} class='col-lg-4'>
                            <ul class='bullets'>
                              <li> {item}</li>
                            </ul>
                          </div>
                        ))}
                      </div>
                      <hr />
                      <h3>Pictures from our users</h3>
                      <div class='pictures_grid magnific-gallery clearfix'>
                        <ImageGallery
                          showFullscreenButton={true}
                          items={hoteldetail.hotel_gallery.map(image => ({
                            original: image, // large image
                            thumbnail: image // thumbnail
                          }))}
                        />
                      </div>
                      <hr />
                      {hoteldetail.rooms_options.map((item, index) => (
                        <div key={index} class='room_type gray mt-2'>
                          <div class='row '>
                            <div class='col-md-4'>
                              {item.rooms_images &&
                              item.rooms_images.length > 0 ? (
                                <img
                                  className='img-fluid'
                                  src={item.rooms_images[0]}
                                  alt={item.room_name}
                                />
                              ) : (
                                <img
                                  src={hoteldetail?.hotel_gallery.length !==0 ?hoteldetail?.hotel_gallery[0] : noImage}
                                  className='img-fluid'
                                  alt='Default Image'
                                />
                              )}
                              <hr className='my-2' />
                              {item.cancliation_policy_arr &&
                              item.cancliation_policy_arr.length > 0 ? (
                                new Date(
                                  item.cancliation_policy_arr[0].from_date
                                ) > todayDate ? (
                                  <p className='text-center'>
                                    <span style={{ color: 'green' }}>
                                      RISK FREE!
                                    </span>{' '}
                                    No cancellation fee before{' '}
                                    {moment(
                                      item.cancliation_policy_arr[0].from_date
                                    ).format('DD-MM-YYYY')}{' '}
                                  </p>
                                ) : (
                                  <p
                                    className='text-center'
                                    style={{ color: 'red' }}
                                  >
                                    Non Refundable
                                  </p>
                                )
                              ) : (
                                <p
                                  className='text-center'
                                  style={{ color: 'red' }}
                                >
                                  Non Refundable
                                </p>
                              )}
                            </div>
                            <div class='col-md-8'>
                              <h4>{item.room_name}</h4>
                              {item.request_type === '1' && (
                                <div className='room-request mb-2'>
                                  <h6>Room on Request</h6>
                                </div>
                              )}
                              <ul class='room-meta my-2'>
                                <li v-if='room.beds_html'>
                                  <Whisper
                                    placement='top'
                                    controlId='control-id-hover'
                                    trigger='hover'
                                    speaker={<Tooltip>No. Rooms</Tooltip>}
                                  >
                                    <div
                                      class='item'
                                      data-toggle='tooltip'
                                      data-placement='top'
                                      title=''
                                      data-original-title='No. Beds'
                                    >
                                      <i class='input-icon field-icon icofont-hotel'></i>
                                      <span v-html='room.beds_html'>
                                        x{item.rooms_qty}
                                      </span>
                                    </div>
                                  </Whisper>
                                </li>
                                <li v-if='room.adults_html'>
                                  <Whisper
                                    placement='top'
                                    controlId='control-id-hover'
                                    trigger='hover'
                                    speaker={<Tooltip>No. Adults</Tooltip>}
                                  >
                                    <div
                                      class='item'
                                      data-toggle='tooltip'
                                      data-placement='top'
                                      title=''
                                      data-original-title='No. Adults'
                                    >
                                      <i class='input-icon field-icon icofont-users-alt-4'></i>
                                      <span v-html='room.adults_html'>
                                        x{item.adults}
                                      </span>
                                    </div>
                                  </Whisper>
                                </li>
                                <li v-if='room.children_html'>
                                  <Whisper
                                    placement='top'
                                    controlId='control-id-hover'
                                    trigger='hover'
                                    speaker={<Tooltip>No. Children</Tooltip>}
                                  >
                                    <div
                                      class='item'
                                      data-toggle='tooltip'
                                      data-placement='top'
                                      title=''
                                      data-original-title='No. Children'
                                    >
                                      <i class='input-icon field-icon fa-child fa'>
                                        <FontAwesomeIcon icon={faChild} />
                                      </i>
                                      <span v-html='room.children_html'>
                                        x{item.childs}
                                      </span>
                                    </div>
                                  </Whisper>
                                </li>
                                <li v-if='room.children_html'>
                                  <Whisper
                                    placement='top'
                                    controlId='control-id-hover'
                                    trigger='hover'
                                    speaker={<Tooltip>{item.board_id}</Tooltip>}
                                  >
                                    <div
                                      class='item'
                                      data-toggle='tooltip'
                                      data-placement='top'
                                      title=''
                                      data-original-title='No. Children'
                                    >
                                      <i class='input-icon field-icon fa-child fa'>
                                        <FontAwesomeIcon icon={faBowlFood} />
                                      </i>
                                      <span v-html='room.children_html'>
                                        Meal
                                      </span>
                                    </div>
                                  </Whisper>
                                </li>
                              </ul>
                              {item.rooms_facilities &&
                                item.rooms_facilities.length > 0 && (
                                  <ul class='hotel_facilities'>
                                    {(item.rooms_facilities.includes(
                                      'Wake-up service'
                                    ) ||
                                      item.rooms_facilities.includes(
                                        'Alarm clock'
                                      )) && (
                                      <li>
                                        <i class='icofont-wall-clock h6 me-2'></i>
                                        Wake-up service
                                      </li>
                                    )}
                                    {item.rooms_facilities.includes('TV') && (
                                      <li>
                                        <i class='icofont-monitor h6 me-2'></i>
                                        TV
                                      </li>
                                    )}
                                    {item.rooms_facilities.includes(
                                      'Shower'
                                    ) && (
                                      <li>
                                        <FontAwesomeIcon
                                          className='me-2'
                                          icon={faShower}
                                        />
                                        Shower
                                      </li>
                                    )}
                                    {item.rooms_facilities.includes('Safe') && (
                                      <li>
                                        <i class='icofont-safety h6 me-2'></i>
                                        Safe
                                      </li>
                                    )}
                                    {item.rooms_facilities.includes(
                                      'Living room'
                                    ) && (
                                      <li>
                                        <i class='icofont-bed h6 me-2'></i>
                                        Living room
                                      </li>
                                    )}
                                  </ul>
                                )}
                                <div className='text-end'>
                                {showPrice ? (
                                  <h6 className='me-2'>
                                    {CurrencyRates === undefined
                                      ? baseCName
                                      : CurrencyRates.selectedcurrency}{' '}
                                    {(renderPrice(
                                      calculateMarkup(item.rooms_total_price),
                                      hoteldetail.hotel_curreny
                                    )/Number(daysBetween)).toFixed(2)}/night
                                  </h6>
                                ) : (
                                  <h6 className='me-2'>
                                    {hoteldetail.hotel_curreny}{' '}
                                    {(calculateMarkup(item.rooms_total_price)/Number(daysBetween)).toFixed(2)}/night
                                  </h6>
                                )}
                                </div>
                              <hr className='my-2' />
                              <div className='d-flex align-items-center justify-content-between'>
                                {showPrice ? (
                                  <h6 className='me-2'>
                                    {CurrencyRates === undefined
                                      ? baseCName
                                      : CurrencyRates.selectedcurrency}{' '}
                                    {renderPrice(
                                      calculateMarkup(item.rooms_total_price),
                                      hoteldetail.hotel_curreny
                                    )}
                                    <br/><p className='vat-text mb-0'>inclusive of Vat and Taxes</p>
                                  </h6>
                                ) : (
                                  <h6 className='me-2'>
                                    {hoteldetail.hotel_curreny}{' '}
                                    {calculateMarkup(item.rooms_total_price)}
                                    <br/><p className='vat-text mb-0'>inclusive of Vat and Taxes</p>
                                  </h6>
                                )}
                                
                                <div>
                                  {hotelSelectedRooms.some(
                                    item => item.index === index
                                  ) ? (
                                    <p
                                      onClick={event =>
                                        selectedRoomData(index, event)
                                      }
                                      class='btn btn-sm btn-danger mb-0'
                                    >
                                      Remove
                                    </p>
                                  ) : (
                                    <p
                                      onClick={event =>
                                        selectedRoomData(index, event)
                                      }
                                      class='btn btn-sm btn-success mb-0'
                                    >
                                      Select Room
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      	<hr/>
                        <h3>Location</h3>
                        
                        <div class="location-map">
                              <div id="map_content">
                              <div style={{ width: '100%' }}>
                                    <iframe
                                      width='100%'
                                      height='300'
                                      frameborder='0'
                                      scrolling='no'
                                      marginheight='0'
                                      marginwidth='0'
                                      src={mapUrl}
                                    ></iframe>
                                  </div>
                              </div>
                          </div>
                    </section>

                  </div>
                  <aside class='col-lg-4' id='sidebar'>
                  <div class="box_detail booking hide-button-mobile theiaStickySidebar">
                    <a onClick={BookRoom} class="  btn_1 full-width purchase">Book Now</a>
                  </div>
                  </aside>
                </div>
              </div>
            </div>
          </main>
        </Layout>
      )}

      <div id='mybutton'>
        <button
          onClick={BookRoom}
          class='btn btn-success'
        >
          Book Now
        </button>
      </div>
    </>
  )
}

export default HotelDetail
